import React from "react";
import { Props } from "../.";
import { ImageAssetUrl } from "../../../../contentstack/content-types/assets/typings";
import ComponentImage from "../../../components/image";
import { UrlFieldType } from "../../../../contentstack/fields/url";

export interface SectionPromoContentstackProps {
  promoItems: Array<
    Pick<Props["promoItems"][0], "description" | "title" | "category"> & {
      url?: UrlFieldType;
      imageUrl: ImageAssetUrl;
    }
  >;
}

export const transformContentstackPropsToSectionProps = ({
  promoItems,
  ...otherProps
}: SectionPromoContentstackProps): Props => {
  return {
    ...otherProps,
    promoItems: promoItems.map(({ url, imageUrl, ...props }) => {
      return {
        ...props,
        link: url,
        image: <ComponentImage src={imageUrl} objectFit={"cover"} />,
      };
    }),
  };
};
