import styled from "styled-components";
import { cssLocalizedUppercase } from "../../css";

export const Category = styled.div`
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.1em;
  ${cssLocalizedUppercase}
  color: #0bc6e3;
`;
