import styled, { css, StyledComponent, FlattenSimpleInterpolation } from "styled-components";
import { rem } from "../../utils/style";
import { COLUMN_WIDTH, BREAKPOINT_MOBILE_ALL } from "../../layouts/default/theme";
import { rgba } from "polished";
import { cssLocalizedUppercase } from "../css";
import RiotLocale from "../../enums/riot-locale";
import riotLocaleToBcp47 from "../../utils/riot-locale-to-bcp47";

export const SIDE_WIDTH_IN_PX = COLUMN_WIDTH;
export const SIDE_WIDTH_SMALL_IN_PX = 20;
export const LAYOUT_BREAKPOINT = BREAKPOINT_MOBILE_ALL;

export const Title = styled.div<{
  cssVerticalAlign: "top" | "center" | "bottom";
}>`
  position: relative;
  align-self: ${({ cssVerticalAlign }) => {
    switch (cssVerticalAlign) {
      case "center":
        return "center";
      case "bottom":
        return "flex-end";
      default:
        return undefined;
    }
  }};
`;

export const TextContainer = styled.div`
  z-index: 10;
  position: relative;
  display: inline-block;

  margin-left: 50%;
  transform: translateX(-50%);
`;

const textStyle = css`
  ${cssLocalizedUppercase}
  font-size: ${rem(10)};
  letter-spacing: 0.25em;
`;

export const Text = styled.div<{ cssColor: string; cssShowLine: boolean }>`
  padding-top: 100%;

  transform: rotate(-90deg) translate(-100%, -50%);
  transform-origin: top left;

  white-space: nowrap;
  color: ${({ cssColor }) => cssColor};

  ${textStyle}
  font-weight: bold;

  &::after {
    content: "";
    display: ${({ cssShowLine }) => (cssShowLine ? "inline-block" : "none")};
    vertical-align: middle;

    width: 1.4em;
    height: 1px;
    margin-left: 0.5em;

    background-color: currentColor;
  }

  &:lang(${riotLocaleToBcp47(RiotLocale.zh_TW)}) {
    writing-mode: vertical-lr;
    text-orientation: upright;
    transform: none;

    &::after {
      display: none;
    }

    &::before {
      transform: rotate(-90deg) translate(-100%, -50%);
      transform-origin: top left;
      content: "";
      display: ${({ cssShowLine }) => (cssShowLine ? "inline-block" : "none")};
      vertical-align: middle;

      width: 1.4em;
      height: 1px;
      margin-left: 0.65rem;
      margin-bottom: 1rem;

      background-color: currentColor;
    }
  }
`;

export const Content = styled.div`
  flex: 1 1 100%;
  min-width: 0;
`;

export const HorizontalTitle = styled.div`
  ${textStyle}
  margin-bottom: 20px;
  font-weight: 600;

  &::after {
    content: "";
    display: inline-block;
    width: 13px;
    height: 2px;
    background-color: ${rgba("#1a1d21", 0.6)};
  }
`;

export const Wrapper = styled.div`
  display: flex;

  width: 100%;
  height: 100%;

  &::after,
  ${Title} {
    width: ${SIDE_WIDTH_IN_PX + "px"};
    flex: 0 0 auto;
  }

  &::after {
    display: block;
    content: "";
  }
`;

export const ResponsiveWrapper = styled(Wrapper)`
  @media (min-width: ${LAYOUT_BREAKPOINT + 1 + "px"}) {
    ${HorizontalTitle} {
      display: none;
    }
  }

  @media (max-width: ${LAYOUT_BREAKPOINT + "px"}) {
    &::after,
    ${Title} {
      width: ${SIDE_WIDTH_SMALL_IN_PX + "px"};
    }

    ${Text} {
      display: none;
    }

    ${HorizontalTitle} {
      display: block;
    }
  }
`;

interface ResponsiveListPullSideProps {
  List: StyledComponent<keyof JSX.IntrinsicElements | React.ComponentType<any>, object>;
  Item: StyledComponent<keyof JSX.IntrinsicElements | React.ComponentType<any>, object>;
  breakpointLarge: number;
  extraCssSmall?: FlattenSimpleInterpolation;
}

export const cssResponsiveListPullSideFn = ({
  List,
  Item,
  breakpointLarge,
  extraCssSmall,
}: ResponsiveListPullSideProps) => {
  return css`
    @media (max-width: ${(breakpointLarge || 1000) + "px"}) {
      ${List} {
        margin-right: ${-1 * SIDE_WIDTH_IN_PX + "px"};
      }

      ${Item} {
        &:last-child {
          padding-right: ${SIDE_WIDTH_IN_PX + "px"};
        }
      }
    }

    /* Section Layout on smaller screens */
    @media (max-width: ${LAYOUT_BREAKPOINT + "px"}) {
      ${List} {
        margin-left: ${-1 * SIDE_WIDTH_SMALL_IN_PX + "px"};
        margin-right: ${-1 * SIDE_WIDTH_SMALL_IN_PX + "px"};
      }

      ${Item} {
        &:first-child {
          padding-left: ${SIDE_WIDTH_SMALL_IN_PX + "px"};
        }
        &:last-child {
          padding-right: ${SIDE_WIDTH_SMALL_IN_PX + "px"};
        }
      }
    }

    ${extraCssSmall ? extraCssSmall : ""}
  `;
};
