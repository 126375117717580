import React, { ReactElement, useEffect, useState } from "react";
import { useInView } from "../../utils/hooks";
import { COLOR_OUTLINE_MEDIUM_GREY } from "../../layouts/default/theme";

import HexOutline, { HexTransitionType } from "../../components/hex-outline";
import Reveal from "../../components/reveal";

import {
  PromoWrapper,
  PromoInner,
  PromoItem,
  ImageContainer,
  Info,
  InfoInner,
  Category,
  Title,
  Description,
} from "./style";
import LinkComponent, { LinkProps } from "../../components/link";

export interface Props {
  promoItems: PromoItem[];
  className?: string;
}

export interface PromoItem {
  image: ReactElement;
  link?: LinkProps;
  category?: string;
  title?: string;
  description?: string;
}

const Section: React.FC<Props> = ({ promoItems, className }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [inViewRef, inView] = useInView({ triggerOnce: true, threshold: 0 });
  const [activeItemIndex, setActiveItemIndex] = useState<null | number>(null);

  useEffect(() => {
    // choose random index
    const index = Math.floor(Math.random() * promoItems.length);
    setActiveItemIndex(index);
  }, [promoItems.length]);

  return (
    <PromoWrapper
      ref={inViewRef}
      className={`${className} ${inView ? "isVisible" : ""}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      data-testid='promo'
    >
      <PromoInner className={activeItemIndex !== null ? "promo-item-selected" : ""}>
        {promoItems.map(({ image, link, category, title, description }, index) => {
          // support promos with link and without link
          let infoDelay = 1500;
          const infos = [];
          if (category) {
            infos.push(
              <Category key={`category_${index}`} delay={infoDelay}>
                {category}
              </Category>,
            );
            infoDelay += 100;
          }
          if (title) {
            infos.push(
              <Title key={`title_${index}`} delay={infoDelay} data-testid='promo:title'>
                {title}
              </Title>,
            );
            infoDelay += 100;
          }
          if (description) {
            infos.push(
              <Description key={`description_${index}`} delay={infoDelay} data-testid='promo:description'>
                {description}
              </Description>,
            );
          }

          return (
            <PromoItem
              {...link}
              key={`promo_${index}`}
              as={link ? LinkComponent : "span"}
              className={activeItemIndex === index ? "active" : ""}
            >
              <ImageContainer data-testid='promo:image'>{image}</ImageContainer>

              {(infos.length || null) && (
                <Info>
                  <Reveal isActive={inView} showDir={"ne"} transitionDelay={800} transitionDuration={1000} />
                  <InfoInner>{infos.map((info) => info)}</InfoInner>
                </Info>
              )}

              <HexOutline
                isActive={inView}
                offsetHorizontal={1}
                offsetVertical={-1}
                clipRightTop={35}
                transition={HexTransitionType.WIRE}
                transitionDuration={1500}
                transitionDelay={0}
                interactive
                hovering={isHovering}
                hoverDuration={300}
                accentColor={COLOR_OUTLINE_MEDIUM_GREY}
              />
            </PromoItem>
          );
        })}
      </PromoInner>
    </PromoWrapper>
  );
};

export default Section;
