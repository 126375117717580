import React from "react";

import { ResponsiveWrapper, HorizontalTitle, Title, TextContainer, Text, Content } from "./style";

interface Props {
  className?: string;
  text?: string;
  textColor?: string;
  verticalAlign?: "top" | "center" | "bottom";
  displayOnMobile?: boolean;
  testId?: string;
}

const Component: React.FC<Props> = ({
  className,
  text = "",
  textColor = "#1c1d2a",
  verticalAlign = "top",
  displayOnMobile,
  children,
  testId,
}) => {
  return (
    <ResponsiveWrapper className={className} data-testid={testId}>
      <Title cssVerticalAlign={verticalAlign}>
        <TextContainer>
          <Text cssColor={textColor} cssShowLine={!!text.trim()}>
            {text}
          </Text>
        </TextContainer>
      </Title>
      <Content>
        {displayOnMobile && text && <HorizontalTitle>{text}</HorizontalTitle>}
        {children}
      </Content>
    </ResponsiveWrapper>
  );
};

export default Component;
export { SIDE_WIDTH_IN_PX, SIDE_WIDTH_SMALL_IN_PX, LAYOUT_BREAKPOINT, cssResponsiveListPullSideFn } from "./style";
