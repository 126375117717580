import { ImageAssetUrl } from "../content-types/assets/typings";

export interface ImageDeliveryApiOptions {
  quality?: number;
  crop?: number | string;
  width?: number | string;
  height?: number | string;
}

const ImageDeliveryApi = (url: ImageAssetUrl, { quality, crop, width, height }: ImageDeliveryApiOptions): string => {
  const params: { [x: string]: string | number } = {};

  // put together all the parameters
  if (quality) params.quality = quality;
  if (crop) params.crop = crop;
  if (width) params.width = width;
  if (height) params.height = height;

  // construct url
  const uri = new URL(url);
  Object.keys(params).forEach((key) => {
    uri.searchParams.set(key, params[key].toString());
  });

  return uri.href;
};

export default ImageDeliveryApi;
