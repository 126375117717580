import React from "react";

import { Category } from "./style";

type Props = React.HTMLAttributes<HTMLDivElement>;

const Component = React.forwardRef<HTMLDivElement, Props>(({ children, ...props }, ref) => {
  return (
    <Category ref={ref} {...props}>
      {children}
    </Category>
  );
});

export default Component;
