import styled from "styled-components";
import { MAX_WIDTH, COLUMN_WIDTH, MOBILE_MARGIN, MEDIA_NOT_MOBILE } from "../../layouts/default/theme";
import { animation, hoverEffect, easing } from "../../utils/style";
import CategoryComponent from "../../components/format/category";
import { RevealWrapper } from "../../components/reveal/style";
import { cssLocalizedLineHeight } from "../../components/css";

export const BREAKPOINT_COLUMN = 1000;
export const MEDIA_COLUMN_LAYOUT = `(max-width: ${BREAKPOINT_COLUMN}px)`;

export const PromoWrapper = styled.section`
  box-sizing: border-box;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  padding: 0 ${COLUMN_WIDTH}px;
  margin: 4% auto;

  @media ${MEDIA_COLUMN_LAYOUT} {
    padding: 0 20px;
    margin-bottom: 40px;
  }

  @media (max-width: 700px) {
    height: 200px;
  }
`;

export const PromoItem = styled.a`
  position: relative;
  width: 100%;
  height: 100%;

  display: block;
  text-decoration: none;
`;

export const PromoInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  html:not(.js) & {
    /* display first item by default when javascript is not enabled
      and hide all others */
    ${PromoItem} {
      &:not(:first-child) {
        display: none;
      }
    }
  }

  html.js & {
    /* make all items invisible, until one is selected,
      but make sure first one takes up space */
    &:not(.promo-item-selected) {
      ${PromoItem} {
        visibility: hidden;

        &:not(:first-child) {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    /* one an item is select, hide all others */
    &.promo-item-selected {
      ${PromoItem}:not(.active) {
        display: none;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;

  width: 100%;
  padding-top: 50%;

  ${hoverEffect.scaleDown(PromoWrapper)}

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-width: 700px) {
      height: 55%;
    }
  }

  ${animation.triggeredFadeIn(`${PromoWrapper}.isVisible &`, {
    delay: 800,
  })}

  @media (min-width: 1320px) {
    padding-top: 650px;
  }

  @media (max-width: 800px) {
    padding-top: 350px;
  }
`;

export const InfoInner = styled.div`
  position: relative;
  padding: 33px ${COLUMN_WIDTH}px 37px;
  transition: transform 300ms ${easing.easeOutQuad};

  @media ${MEDIA_COLUMN_LAYOUT} {
    padding: 20px;
  }
`;

export const Info = styled.div`
  box-sizing: border-box;
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 600px;

  color: white;

  ${RevealWrapper} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0c2f3f;
    transition: transform 300ms ${easing.easeOutQuad};
    transform-origin: left;
  }

  ${PromoWrapper}:hover & {
    ${RevealWrapper} {
      transform: scaleX(1.01);
    }
    ${InfoInner} {
      transform: translate(12px, 0);
    }
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    width: auto;
    max-width: auto;
    left: 12px;
    right: 12px;
  }
`;

const getTriggeredTranslateFadeIn = (delay: number) =>
  animation.triggeredTranslateFadeIn(`${PromoWrapper}.isVisible &`, { delay });

export const Category = styled(CategoryComponent)<{ delay: number }>`
  margin-bottom: 5px;
  color: inherit;

  ${({ delay }) => getTriggeredTranslateFadeIn(delay)}
`;

export const Title = styled.h1<{ delay: number }>`
  margin: 0;
  font-size: 24px;
  ${cssLocalizedLineHeight(28 / 24)}
  letter-spacing: 0.05em;

  ${({ delay }) => getTriggeredTranslateFadeIn(delay)}

  @media ${MEDIA_COLUMN_LAYOUT} {
    font-size: 18px;
    letter-spacing: 0.05em;
  }
`;

export const Description = styled.p<{ delay: number }>`
  margin-top: 8px;
  font-size: 14px;
  ${cssLocalizedLineHeight(18 / 14)}
  letter-spacing: 0.05em;

  ${({ delay }) => getTriggeredTranslateFadeIn(delay)}

  @media ${MEDIA_COLUMN_LAYOUT} {
    font-size: 14px;
    ${cssLocalizedLineHeight(20 / 14)}
    letter-spacing: 0.04em;
  }
`;
