import { default as styled } from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;

  pointer-events: none;
`;

export const Canvas = styled.canvas`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
`;
