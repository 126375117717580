import React from "react";
import { default as ComponentImage, ComponentImageProps } from "../../../lib/components/image";
import { ImageAssetUrl } from "../../content-types/assets/typings";
import ImageDeliveryApi from "../../utils/image-delivery-api";

interface Props extends Omit<ComponentImageProps, "src" | "srcset"> {
  src: ImageAssetUrl;
  quality?: number;
  crop?: string | number;
  responsiveSizes?: Array<
    | {
        width: number;
        height?: number;
      }
    | number
  >;
}

const ContentstackImage: React.FC<Props> = ({ src, quality = 90, crop, responsiveSizes = [], ...otherProps }) => {
  const normalizedResponsiveSizes = responsiveSizes
    .map((size) => {
      const width = typeof size === "number" ? size : size.width;
      const height = typeof size === "number" ? undefined : size.height;

      return { width, height };
    })
    .sort((a, b) => {
      return b.width - a.width;
    });

  const responsiveImages = normalizedResponsiveSizes.map(({ width, height }) => {
    return {
      url: ImageDeliveryApi(src, {
        quality,
        crop,
        width,
        height,
      }),
      width,
    };
  });

  return (
    <ComponentImage
      {...otherProps}
      src={responsiveImages.length <= 0 ? ImageDeliveryApi(src, { quality }) : responsiveImages[0].url}
      srcSet={
        responsiveImages.length <= 0
          ? undefined
          : responsiveImages.map(({ url, width }) => `${url} ${width}w`).join(", ")
      }
    />
  );
};

export default ContentstackImage;
